#root {
  height: 100%;
  max-width: 640px;
  margin: 0 auto;
}

html,
body {
  height: 100%;
}
